import React from 'react'
import './ChatHeader.css'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import db from '../firebase';
import { selectserverName } from '../features/counter/serverSlice';
import { useSelector } from 'react-redux';

function ChatHeader( {channelName} ) {
  const serverName = useSelector(selectserverName);

  const addPeople = async () => {
    const email_or_name = prompt('1: (email) Enter the email of the person you want to add to the chat\n2: (name) Enter the name of the person you want to add to the chat');
    if (email_or_name === 'email') {
      const email = prompt('Enter the email of the person you want to add to the chat');

      if (email) {
        const userRef = query(collection(db, 'users'), where('email', '==', email));
        const querySnapshot = await getDocs(userRef);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].data();
            const userServers = userDoc.servers;
            userServers.push(serverName);
            console.log(userServers);
            await updateDoc(querySnapshot.docs[0].ref, {
                servers: userServers,
            });
        }
      } else {
        alert('Please enter a valid email address');
      }
    } else if (email_or_name === 'name') {
      var name = prompt('Enter the id, don\'t forget to add the # before the id, of the person you want to add to the chat');
      name = name.substring(1);

      if (name) {
        const userRef = query(collection(db, 'users'), where('id', '==', name));
        const querySnapshot = await getDocs(userRef);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].data();
            const userServers = userDoc.servers;
            userServers.push(serverName);
            console.log(userServers);
            await updateDoc(querySnapshot.docs[0].ref, {
                servers: userServers,
            });
        }
      } else {
        alert('Please enter a valid name');
      }
    }
  }

  return (
    <div className='chatHeader'>
        <div className='chatHeader__left'>
            <h3><span className='chatHeader__hash'>#</span>{channelName}</h3>
        </div>
        <div className='chatHeader__right'>
          <AddCircleOutlineIcon className='addIcon' onClick={addPeople} />
        </div>
    </div>
  )
}

export default ChatHeader
