import { createSlice } from "@reduxjs/toolkit";

const serversSlice = createSlice({
name: "servers",
  initialState: {
    serverId: null,
    serverName: null,
  },
  reducers: {
    setserverInfo: (state, action) => {
      state.serverId = action.payload.serverId;
      state.serverName = action.payload.serverName;
    },
  },
});

export const { setserverInfo } = serversSlice.actions;

export const selectserverId = (state) => state.servers.serverId;
export const selectserverName = (state) => state.servers.serverName;

export default serversSlice.reducer;