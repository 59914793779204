import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, FieldValue } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAfsDh0pEJyZ_1UgVE-sLjtKQiV6DZVey4",
    authDomain: "mydiscord-98c38.firebaseapp.com",
    projectId: "mydiscord-98c38",
    storageBucket: "mydiscord-98c38.appspot.com",
    messagingSenderId: "389720073247",
    appId: "1:389720073247:web:02a791aad0cf8d4282212a"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
const storage = getStorage(firebaseApp);

export { auth, provider, FieldValue, storage };
export default getFirestore(firebaseApp);
