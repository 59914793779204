import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/counter/userSlice';
import appReducer from '../features/counter/appSlice';
import serversReducer from '../features/counter/serverSlice';


export const store = configureStore({
    reducer: {
        user: userReducer,
        app: appReducer,
        servers: serversReducer,
    },
});

export default store;