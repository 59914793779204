import './App.css';
import LeftSidebar from './left/leftSidebar';
import Login from './Login';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { login, logout, selectUser } from './features/counter/userSlice';
import { auth } from './firebase';
import db from './firebase';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import MiddleSidebar from './middle/MiddleSidebar';
import Chat from './right/Chat';

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [users, setUsers] = useState([]);

  console.log(users);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }));
      setUsers(usersData);
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        console.log("L'utilisateur est connecté");
        dispatch(login({
          uid: authUser.uid,
          photo: authUser.photoURL,
          email: authUser.email,
          displayName: authUser.displayName,
        }));

        const userRef = query(collection(db, 'users'), where('email', '==', authUser.email));
        const querySnapshot = await getDocs(userRef);
        if (querySnapshot.empty) {
          const newUserRef = await addDoc(collection(db, "users"), {
            email: authUser.email,
            displayName: authUser.displayName,
            servers: ['default'],
            id: authUser.uid.uid.substring(0, 8),
          });
          console.log("Nouvel utilisateur ajouté avec l'ID : ", newUserRef.id);
          window.location.reload();
        } else {
          console.log("L'utilisateur existe déjà");
        }
      } else {
        console.log("L'utilisateur est déconnecté");
        dispatch(logout());
      }
    });

    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    document.title = "Shirosaaki";
  }, []);  

  return (
    <div className="App">
      <head>
        <title>Shirosaaki</title>
      </head>

      { user ? (
        <>
          <LeftSidebar />

          <MiddleSidebar />

          <Chat />
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
