import React, { useState } from 'react';
import "./leftSidebar.css";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Servers from "./servers";
import { collection, addDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import db from '../firebase';
import { useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/counter/userSlice';
import { query, where, getDocs } from "firebase/firestore";

function LeftSidebar() {
    const [image, setImage] = useState(null);
    const [servers, setServers] = useState([]);

    const user = useSelector(selectUser);

    console.log(image);

    useEffect(() => {
        onSnapshot(collection(db, 'servers'), async (snapshot) => {
            const userRef = query(collection(db, 'users'), where('email', '==', user.email));
            const querySnapshot = await getDocs(userRef);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0].data();
                const userServers = userDoc.servers;
                const filteredServers = snapshot.docs.filter(doc => userServers.includes(doc.data().serverName)).map(doc => ({id: doc.id, server: doc.data()}));
                
                filteredServers.sort((a, b) => a.server.createdAt - b.server.createdAt);
                setServers(filteredServers);
            } else {
                setServers([]);
            }
        });
    }, [user]);

    const selectImage = () => {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.onchange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    resolve(file);
                } else {
                    reject(new Error('No file selected'));
                }
            };
            input.click();
        });
    }

    const addServer = async (e) => {
        try {
            const serverName = prompt('Enter a new server name');

            if (serverName) {
                const selectedImage = await selectImage();
                setImage(selectedImage);

                const storage = getStorage();
                const storageRef = ref(storage, `server_images/${selectedImage.name}`);

                await uploadBytes(storageRef, selectedImage);
                const imageURL = await getDownloadURL(storageRef);

                await addDoc(collection(db, "servers"), {
                    serverName: serverName,
                    imageURL: imageURL,
                    createdAt: new Date(),
                });
                
                setImage(null);

                const userRef = query(collection(db, 'users'), where('email', '==', user.email));
                const querySnapshot = await getDocs(userRef);
    
                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0].data();
                    const userServers = userDoc.servers;
                    userServers.push(serverName);
                    console.log(userServers);
                    await updateDoc(querySnapshot.docs[0].ref, {
                        servers: userServers,
                    });
                }
            } else {
                alert('Please provide a server name');
            }
        } catch (error) {
            console.error('Error uploading image: ', error);
        }
    }

    return (
        <div className='leftSidebar'>
            <div className='servers'>
                { console.log(servers) }
                {
                servers.map((server) => (
                    <Servers key={server.id} serverName={server.server.serverName} imageURL={server.server.imageURL} id={server.id} />
                ))}
            </div>
            <input type="file" id="serverImage" accept="image/*" style={{display: 'none'}}></input>
            <AddCircleOutlineIcon className='addIcon' onClick={addServer} />
        </div>
    );
}

export default LeftSidebar;
