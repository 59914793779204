import React from 'react'
import "./servers.css"
import Avatar from '@mui/material/Avatar';
import { useDispatch } from 'react-redux';
import { setserverInfo } from '../features/counter/serverSlice';
import { setChannelInfo } from '../features/counter/appSlice';

function Servers({ serverName, imageURL, id }) {
  const dispatch = useDispatch();

  return (
    <div className='servers'>
        <Avatar src={imageURL} onClick={() => {
          dispatch(setserverInfo({
            serverId: id,
            serverName: serverName,
          })
        );
        dispatch(setChannelInfo({
          channelId: null,
          channelName: null,
        }));
        }} className='avatar'/>
    </div>
  )
}

export default Servers