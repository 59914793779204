import React, { useEffect, useState, useRef } from 'react';
import './Chat.css';
import ChatHeader from './ChatHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import GifIcon from '@mui/icons-material/Gif';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Message from './Message';
import { useSelector } from 'react-redux';
import { selectChannelId, selectChannelName } from '../features/counter/appSlice';
import { selectUser } from '../features/counter/userSlice';
import db from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
import { selectserverId } from '../features/counter/serverSlice';
import { doc } from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';
import { storage } from '../firebase.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { orderBy } from 'firebase/firestore';
import { query } from 'firebase/firestore';


function Chat() {
    const channelId = useSelector(selectChannelId);
    const channelName = useSelector(selectChannelName);
    const user = useSelector(selectUser);
    const serverId = useSelector(selectserverId);

    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);

    const [file, setFile] = useState(null);

    const messagesEndRef = useRef(null);

    console.log(setMessages);

    async function uploadFile(file) {
        const storageRef = ref(storage, file.name);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    }

    useEffect(() => {
        if (channelId) {
            const serverRef = doc(db, 'servers', serverId);
            const channelsRef = collection(serverRef, 'channels');
            const messagesRef = collection(channelsRef, channelId, 'messages');
    
            // Ajoutez une clause orderBy pour trier les messages par timestamp
            const qquery = query(messagesRef, orderBy("timestamp"));
    
            onSnapshot(qquery, (snapshot) => {
                setMessages(snapshot.docs.map(doc => doc.data()));
            });
        } else {
            setMessages([]);
        }
    }, [channelId, serverId, channelName]);


    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        if (channelId) {
          const serverRef = doc(db, 'servers', serverId);
          const channelsRef = collection(serverRef, 'channels');
          const messagesRef = collection(channelsRef, channelId, 'messages');
      
          if (file !== null) {
            const fileUrl = await uploadFile(file);
            await addDoc(messagesRef, {
              timestamp: serverTimestamp(),
              message: fileUrl,
              user: user,
              channelName: channelName,
            });
          } else if (input !== ''){
            await addDoc(messagesRef, {
              timestamp: serverTimestamp(),
              message: input,
              user: user,
              channelName: channelName,
            });
            }
          setInput('');
          setFile(null);
        }
    };      

    const addFile = () => {
        document.querySelector('input[type="file"]').click();
    }

    return (
        <div className='chat'>
            <div className='chat__header'>
                <ChatHeader channelName={channelName} />
            </div>

            <div className='chat__messages'>
                {messages.map((message, index) => (
                    <Message
                        key={index}
                        message={message.message}
                        timestamp={message.timestamp}
                        user={message.user}
                    />
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div className='chat__input'>
                <AddCircleIcon fontSize='large' onClick={addFile}/>
                <form>
                    <input
                        disabled={!channelId}
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder={`Message #${channelName}`}
                    />
                    <input type="file" onChange={e => setFile(e.target.files[0])} />
                    <button onClick={handleSubmit} disabled={!channelId} className='chat__inputButton' type='submit'>Send Message</button>
                </form>

                <div className='chat__inputIcons'>
                    <CardGiftcardIcon fontSize='large' />
                    <GifIcon fontSize='large' />
                    <EmojiEmotionsIcon fontSize='large' />
                </div>
            </div>
        </div>
    )
}

export default Chat;
