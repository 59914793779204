import React from 'react'
import './MiddleSidebar.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import SidebarChannel from './SidebarChannel';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import InfoIcon from '@mui/icons-material/Info';
import CallIcon from '@mui/icons-material/Call';
import { Avatar } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import SettingsIcon from '@mui/icons-material/Settings';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/counter/userSlice';
import { auth } from '../firebase';
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import db from '../firebase';
import { addDoc, doc } from 'firebase/firestore';
import { selectserverId, selectserverName } from '../features/counter/serverSlice';
import { orderBy } from 'firebase/firestore';
import { query } from 'firebase/firestore';

function MiddleSidebar() {
    const user = useSelector(selectUser);
    const [channels, setChannels] = useState([]);

    const serverId = useSelector(selectserverId);
    const serverName = useSelector(selectserverName);

    useEffect(() => {
        if (serverId) {
            const serverRef = doc(db, 'servers', serverId);
            const channelsRef = collection(serverRef, 'channels');
    
            const unsubscribe = onSnapshot(query(channelsRef, orderBy("date")), (snapshot) => {
                setChannels(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        channel: doc.data(),
                    }))
                );
            });
            return () => unsubscribe();
        }
    }, [serverId, channels]);

    const addChannel = async () => {
        const channelName = prompt('Enter a new channel name');

        if (channelName && serverId) {
            const serverRef = doc(db, 'servers', serverId);
            const channelsRef = collection(serverRef, 'channels');

            await addDoc(channelsRef, {
                channelName: channelName,
                date: new Date(),
            });
        }
    }

  return (
    <div className='sidebar'>
        <div className='sidebar__top'>
            <h3>{serverName}</h3>
            <ExpandMoreIcon />
        </div>

        <div className='sidebar__channels'>
            <div className='sidebar__channelsHeader'>
                <div className='sidebar__header'>
                    <ExpandMoreIcon />
                    <h4>Text Channels</h4>
                </div>

                <AddIcon onClick={addChannel} className='sidebar__addChannel' />
            </div>
        
            <div className='sidebar__channelsList'>
                {channels.map(({ id, channel }) => (
                    <SidebarChannel key={id} id={id} channelName={channel.channelName} />
                ))}
            </div>
        </div>

        <div className='sidebar__voice'>
            <SignalCellularAltIcon className='sidebar__voiceIcon' fontSize='large'/>
            <div className='sidebar__voiceInfo'>
                <h3>Voice Connected</h3>
                <p>Stream</p>
            </div>

            <div className='sidebar__voiceInons'>
                <InfoIcon />
                <CallIcon />
            </div>
        </div>

        <div className='sidebar__profile'>
            <Avatar onClick={() => auth.signOut()} src={user.photo}/>
            <div className='sidebar__profileInfo'>
                <h3>{user.displayName}</h3>
                <p>#{user.uid.substring(0, 8)}</p>
            </div>

            <div className='sidebar__profileIcons'>
                <MicIcon />
                <HeadphonesIcon />
                <SettingsIcon />
            </div>
        </div>
    </div>
  )
}

export default MiddleSidebar
