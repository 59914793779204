import React from 'react';
import './Login.css';
import { Button } from '@mui/material';
import { auth, provider } from './firebase';
import { signInWithPopup } from "firebase/auth";

function Login() {
  const signIn = async () => { // Utilisez async/await pour une meilleure gestion des erreurs
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("oui");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className='login'>
      <Button onClick={signIn}>Se connecter</Button>
    </div>
  );
}

export default Login;
