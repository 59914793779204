import React from 'react'
import './Message.css'
import { Avatar } from '@mui/material'

function Message( {message, timestamp, user} ) {
    const formattedDate = new Date(timestamp?.toDate()).toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
    
    return (
        <div className='message'>
            <Avatar src={user.photo} />
            <div className='message__info'>
                <h4>
                    {user.displayName}
                    <span className='message__timestamp'>
                        {formattedDate}
                    </span>
                </h4>
                {message.startsWith('http') ? (
                    <img src={message} alt="" />
                ) : (
                    <p>{message}</p>
                )}
            </div>
        </div>
    )
}

export default Message
